import { createAction, props } from '@ngrx/store';
import { SchedulePensionsJobRequest } from '@app/management/models/requests/schedule-pension-job-request';
import { getActionName } from '@app/core/state/state-helpers';
import { ScheduleRebateCalculationJobRequest } from '@app/management/models/requests/schedule-rebate-calculation-job-request';
import { UpdateRebateFeesRequest } from '@app/management/models/requests/update-rebate-fees-request';
import { RebateControl } from '@app/management/models/responses/rebate-control';
import { ScheduleStatementGenerationRequest } from '@app/management/models/requests/schedule-statement-generation-request';

const actionPrefix = '[Management]';
export const schedulePensionJob = createAction(
  getActionName('Schedule Pension Job', actionPrefix),
  props<{ request: SchedulePensionsJobRequest }>(),
);

export const schedulePensionJobSuccess = createAction(
  getActionName('Schedule Pension Job Success', actionPrefix),
);

export const schedulePensionJobFailure = createAction(
  getActionName('Schedule Pension Job Failure', actionPrefix),
  props<{ error: string }>(),
);

export const scheduleRebateCalculation = createAction(
  getActionName('Schedule Rebate Job', actionPrefix),
  props<{ request: ScheduleRebateCalculationJobRequest }>(),
);

export const scheduleRebateCalculationSuccess = createAction(
  getActionName('Schedule Rebate Job Success', actionPrefix),
);

export const scheduleRebateCalculationFailure = createAction(
  getActionName('Schedule Rebate Job Failure', actionPrefix),
  props<{ error: string }>(),
);

export const scheduleReinsuranceEventFieldSync = createAction(
  getActionName('Schedule Reinsurance Event Field Sync Job', actionPrefix),
);

export const scheduleReinsuranceEventFieldSyncSuccess = createAction(
  getActionName(
    'Schedule Reinsurance Event Field Sync Job Success',
    actionPrefix,
  ),
);

export const scheduleReinsuranceEventFieldSyncFailure = createAction(
  getActionName(
    'Schedule Reinsurance Event Field Sync Job Failure',
    actionPrefix,
  ),
  props<{ error: string }>(),
);

export const scheduleStatementGeneration = createAction(
  getActionName('Schedule Statement Generation Job', actionPrefix),
  props<{ request: ScheduleStatementGenerationRequest }>(),
);

export const scheduleStatementGenerationSuccess = createAction(
  getActionName('Schedule Statement Generation Job Success', actionPrefix),
);

export const scheduleStatementGenerationFailure = createAction(
  getActionName('Schedule Statement Generation Job Failure', actionPrefix),
  props<{ error: string }>(),
);

export const updateRebateFees = createAction(
  getActionName('Update Rebate Fees', actionPrefix),
  props<{ request: UpdateRebateFeesRequest }>(),
);

export const updateRebateFeesSuccess = createAction(
  getActionName('Update Rebate Fees Success', actionPrefix),
);

export const updateRebateFeesFailure = createAction(
  getActionName('Update Rebate Fees Failure', actionPrefix),
  props<{ error: string }>(),
);

export const loadRebateControls = createAction(
  getActionName('Load rebate controls', actionPrefix),
);

export const loadRebateControlsSuccess = createAction(
  getActionName('Load rebate controls success', actionPrefix),
  props<{ response: RebateControl[] }>(),
);

export const loadRebateControlsFailure = createAction(
  getActionName('Load rebate controls failure', actionPrefix),
  props<{ error: string }>(),
);
